import React, { useState, useEffect, useContext, lazy } from 'react';
import { useLocation } from "react-router-dom";
import { Context } from './Context/AuthContext';


export default function Layout({ children }) {
    const [isLogin, setIsLogin] = useState(true);
    const location = useLocation()
    const { user, loading, authenticated } = useContext(Context);

    return (
        <React.Fragment>
            {
                (<div>{children}</div>) 
            }
          
        </React.Fragment>
    )
}